* {
  /* outline: 1px #00ff00 solid; */
}

#root {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  width: 100%;
}

body.dialog-scroll-freeze {
  position: fixed;
}

body.dialog-scroll-freeze #root {
  position: unset !important;
  transform: unset !important;
}

body.dialog-scroll-freeze #gnb {
  position: fixed;
  max-width: 500px;
}

body.dialog-scroll-freeze #container {
  padding-top: 56px;
}

#container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#container > .body {
  flex: 1;
  display: flex;
  flex-direction: column;
}

#container > .body > div {
  flex: 1;
  padding-bottom: 100px;
}

/* Pretendard */
@font-face {
	font-family: 'Pretendard';
	font-weight: 900;
	font-display: swap;
	src: local('Pretendard Black'), url('../public/fonts/pretendard/woff2/Pretendard-Black.woff2') format('woff2'), url('../public/fonts/pretendard/woff/Pretendard-Black.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 800;
	font-display: swap;
	src: local('Pretendard ExtraBold'), url('../public/fonts/pretendard/woff2/Pretendard-ExtraBold.woff2') format('woff2'), url('../public/fonts/pretendard/woff/Pretendard-ExtraBold.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 700;
	font-display: swap;
	src: local('Pretendard Bold'), url('../public/fonts/pretendard/woff2/Pretendard-Bold.woff2') format('woff2'), url('../public/fonts/pretendard/woff/Pretendard-Bold.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 600;
	font-display: swap;
	src: local('Pretendard SemiBold'), url('../public/fonts/pretendard/woff2/Pretendard-SemiBold.woff2') format('woff2'), url('../public/fonts/pretendard/woff/Pretendard-SemiBold.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 500;
	font-display: swap;
	src: local('Pretendard Medium'), url('../public/fonts/pretendard/woff2/Pretendard-Medium.woff2') format('woff2'), url('../public/fonts/pretendard/woff/Pretendard-Medium.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 400;
	font-display: swap;
	src: local('Pretendard Regular'), url('../public/fonts/pretendard/woff2/Pretendard-Regular.woff2') format('woff2'), url('../public/fonts/pretendard/woff/Pretendard-Regular.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 300;
	font-display: swap;
	src: local('Pretendard Light'), url('../public/fonts/pretendard/woff2/Pretendard-Light.woff2') format('woff2'), url('../public/fonts/pretendard/woff/Pretendard-Light.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 200;
	font-display: swap;
	src: local('Pretendard ExtraLight'), url('../public/fonts/pretendard/woff2/Pretendard-ExtraLight.woff2') format('woff2'), url('../public/fonts/pretendard/woff/Pretendard-ExtraLight.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 100;
	font-display: swap;
	src: local('Pretendard Thin'), url('../public/fonts/pretendard/woff2/Pretendard-Thin.woff2') format('woff2'), url('../public/fonts/pretendard/woff/Pretendard-Thin.woff') format('woff');
}

/* S Core Dream */
@font-face {
  font-family: 'SCoreDream';
  font-weight: 100; 
  font-style: normal; 
  src: url(../public/fonts/scoredream/SCoreDream1.woff2) format('woff2'), url(../public/fonts/scoredream/SCoreDream1.woff) format('woff');
  font-display: swap;
}

/*
@font-face {
  font-family: 'SCoreDream';
  font-weight: 200; 
  font-style: normal; 
  src: url(../public/fonts/scoredream/SCoreDream2.woff2) format('woff2'), url(../public/fonts/scoredream/SCoreDream2.woff) format('woff');
  font-display: swap;
}
*/

@font-face {
  font-family: 'SCoreDream';
  font-weight: 300; 
  font-style: normal; 
  src: url(../public/fonts/scoredream/SCoreDream3.woff2) format('woff2'), url(../public/fonts/scoredream/SCoreDream3.woff) format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'SCoreDream';
  font-weight: 400; 
  font-style: normal; 
  src: url(../public/fonts/scoredream/SCoreDream4.woff2) format('woff2'), url(../public/fonts/scoredream/SCoreDream4.woff) format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'SCoreDream';
  font-weight: 500; 
  font-style: normal; 
  src: url(../public/fonts/scoredream/SCoreDream5.woff2) format('woff2'), url(../public/fonts/scoredream/SCoreDream5.woff) format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'SCoreDream';
  font-weight: 600; 
  font-style: normal; 
  src: url(../public/fonts/scoredream/SCoreDream6.woff2) format('woff2'), url(../public/fonts/scoredream/SCoreDream6.woff) format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'SCoreDream';
  font-weight: 700; 
  font-style: normal; 
  src: url(../public/fonts/scoredream/SCoreDream7.woff2) format('woff2'), url(../public/fonts/scoredream/SCoreDream7.woff) format('woff');
  font-display: swap;
}

/*
@font-face {
  font-family: 'SCoreDream';
  font-weight: 800; 
  font-style: normal; 
  src: url(../public/fonts/scoredream/SCoreDream8.woff2) format('woff2'), url(../public/fonts/scoredream/SCoreDream8.woff) format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'SCoreDream';
  font-weight: 900; 
  font-style: normal; 
  src: url(../public/fonts/scoredream/SCoreDream9.woff2) format('woff2'), url(../public/fonts/scoredream/SCoreDream9.woff) format('woff');
  font-display: swap;
}
*/

* {
  font-family: 'Noto Sans KR', sans-serif;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  width: 100vw;
  overflow-x: hidden;
}

body {
  background: #ffffff;
}

button {
  cursor: pointer;
  transition: 400ms opacity, 200ms background, 200ms border;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

button:active {
  opacity: 0.5;
  transition: none;
}

a {
  color: inherit;
  text-decoration: none;
}

.swiper-pagination { 
  bottom: calc(100vh / 2 - var(--home-slider-max-height) - 210px) !important;
  pointer-events: none;
}

.slider-pagination-bullet {
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background: #D9D9D9;
  display: inline-block;
}

.slider-pagination-bullet-active {
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background: #0762C8;
  display: inline-block;
}

.slider-pagination-bullet + .slider-pagination-bullet {
  margin-left: 4px;
}

.react-slidedown.my-dropdown-slidedown {
  transition-duration: 400ms;
}
